<template>
  <div class="content">
    <header class="header">
      <part-title title="固件版本"></part-title>
      <el-button  class="content-btn" size="small" type="primary" @click="uploadFirmware">
        <i class="el-icon-plus"></i>
        上传固件
      </el-button>
    </header>
    <el-table
    :data="tableData">
      <el-table-column
        align="center"
        fixed
        label="序号"
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        align="center"
        label="应用">
          <template slot-scope="scope">
            {{scope.row.deviceType === 0 ? 'HFM':'ICM'}}
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="版本号">
          <template slot-scope="scope">
            <div v-if="scope.row.version">
            {{scope.row.version}}
           </div>
           <div v-else>-</div>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="状态">
          <template slot-scope="scope">
            {{scope.row.status === 0 ? '未发布':'已发布'}}
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="brandModel"
        label="发布时间"
        min-width="110">
        <template slot-scope="scope">
            <span v-if="scope.row.updateAt">{{moment.unix(scope.row.updateAt / 1000).format('Y-MM-DD HH:mm:ss')}}</span>
            <span v-else>-</span>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="operator"
        label="创建人">
        <template slot-scope="scope">
            <span v-if="scope.row.operator">{{scope.row.operator}}</span>
            <span v-else>-</span>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="createAt"
        label="创建时间"
        min-width="110">
          <template slot-scope="scope">
            <span v-if="scope.row.createAt">{{moment.unix(scope.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')}}</span>
            <span v-else>-</span>
          </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        min-width="130">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status === 0 ? true : false" class="detail-btn" @click="onRelease(scope.row)" type="text">发布</el-button>
          <el-button v-if="scope.row.status === 0 ? true : false" class="detail-btn" @click="onEdit(scope.row)" type="text">编辑</el-button>
          <el-button class="detail-btn" @click="onDetails(scope.row)" type="text">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 80px"></div>
    <div class="paginationClass" v-if="tableData.length > 0">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchData.pageNo"
      :page-size="searchData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalPage">
      </el-pagination>
    </div>
    <Details
      :visible.sync="isShowDetails"
      :firmwareInfo="firmwareInfo"
    ></Details>
  </div>
</template>

<script>
import { getFeedBackList, releaseFeedBack } from '@/serve/module/firmwareUpdateManage'
import { symptomOpts } from '@/utils/enum'
import PartTitle from '@/components/Part'
import Details from './details.vue'
const moment = require('moment')
export default {
  components: {
    PartTitle,
    Details
  },
  data () {
    return {
      moment: moment,
      symptomOpts,
      searchData: { // 搜索数据
        pageNo: 1, // 当前页
        pageSize: 20 // 每页条数
      },

      totalPage: 1,
      tableData: [],

      enterReceiptDrawer: false,

      firmwareInfo: {},
      searchOptions: {},

      isShowDetails: false,
      isShowRelease: true

    }
  },
  created () {
    this.getFeedBackList(this.searchData)
  },
  methods: {
    handleSizeChange (val) {
      this.searchData.pageSize = val
      this.getFeedBackList(this.searchData)
    },
    handleCurrentChange (val) {
      this.searchData.pageNo = val
      this.getFeedBackList(this.searchData)
    },
    getFeedBackList (params) {
      getFeedBackList(params).then(res => {
        if (res) {
          this.tableData = res.data.data
          this.totalPage = res.data.total
        }
      })
    },
    uploadFirmware () {
      this.$router.push({
        name: 'uploadFirmware'
      })
    },
    onRelease (row) {
      this.$confirm('是否发布?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        releaseFeedBack({
          firmwareId: row.id
        }).then(res => {
          if (res.code === 200) {
            this.getFeedBackList(this.searchData)
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {
      })
    },
    onEdit (row) {
      this.$router.push({
        name: 'editFirmware',
        query: {
          id: row.id
        }
      })
    },
    onDetails (row) {
      this.isShowDetails = true
      this.firmwareInfo = row
    },
    enterReceiptSuccess1 () {
      this.searchData.pageSize = 20
      this.searchData.pageNo = 1
      this.getDeviceReceiptList(this.searchData)
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 0 24px;
}
.form {
  width: 100%;
}
.el-input {
  display: inline-block;
  width: 192px;
  margin-right: 24px;
}
.content-btn {
    float: right;
    margin-bottom: 12px;
  }
.paginationClass {
background: #fff;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 210px;
    z-index: 100;
    padding: 24px 20px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
::v-deep .el-table th.el-table__cell>.cell {
  color: #909399;
  font-weight: 400;
  font-size: 14px;
 }
.receipt-btn {
  float: right;
  margin-bottom: 10px;
}
.detail-btn {
  font-size: 14px;
  font-weight: 400;
}
</style>
